import React from 'react'
import ReactDOM from 'react-dom'
import NumberPicker from 'number-picker'
import { ThemeProvider } from 'providers'
import './index.css'
import { lighten } from '@material-ui/core'
import { theme } from 'get-theme'
import { NumberPickerLoadParameters } from 'number-picker/API'
import { NumberPickerEventDelegates } from 'number-picker/NumberPicker'
const _ = require('lodash'); // eslint-disable-line

interface NumberPickerTheme {
    primaryColor: 'string';
    secondaryColor: 'string';
}

const getContainer = (renderId: string) => {
    const container = document.getElementById(renderId)
    if (!container) {
        throw new Error(`pdcNumberPicker: can not find container element with ID: ${renderId}`)
    }
    return container
}

const createThemeOverride = (numberPickerTheme: NumberPickerTheme) => {
    const { primaryColor, secondaryColor } = numberPickerTheme

    return {
        palette: {
            primary: {
                main: primaryColor
            },
            info: {
                textDark: secondaryColor
            },
            action: {
                infoFocus: lighten(secondaryColor, 0.8)
            }
        },
        overrides: {
            MuiFormControl: {
                root: {
                    "& [class*='MuiInputBase-root']": {
                        '&.Mui-focused': {
                            '&:not(.Mui-error)': {
                                background: `${lighten(primaryColor, 0.9)} !important`
                            }
                        }
                    }
                }
            },
            MuiInputBase: {
                root: {
                    '&$focused:not($error)': {
                        backgroundColor: `${lighten(primaryColor, 0.9)} !important`
                    }
                }
            },
            MuiFilledInput: {
                root: {
                    '&:hover': {
                        backgroundColor: lighten(primaryColor, 0.9)
                    }
                },
                underline: {
                    '&::after': {
                        borderBottom: `1px solid ${primaryColor} !important`
                    }
                },
                adornedEnd: {
                    '& .adorned-end-x': {
                        '& svg': {
                            color: primaryColor
                        }
                    }
                }
            }
        }
    }
}

// Since were exposing the NumberPicker component as a library, adding
// very helpful error handling that gracefully avoids failing is useful.
const validateNumberPickerOptions = (loadParams: NumberPickerLoadParameters, delegates: NumberPickerEventDelegates) => {
    const effectiveLoadParams = loadParams
    const effectiveDelegates = delegates

    const validLoadParams = ['type', 'recommendANumber', 'showTypeSelector', 'npa', 'showNpaSelector', 'keyword', 'showKeywordSearch', 'searchOnLoad', 'scrollToNewNumbers', 'resultsQty', 'allowMoreNumbers']
    const validDelegates = ['onNumberSelected']

    const reportError = (keyType: 'loadParams' | 'delegates', key: string, errorMessage: string) => {
        console.error(`pdcNumberPicker: ${keyType}.${key}: ${errorMessage}`)
        if (keyType === 'loadParams') {
            delete effectiveLoadParams[key]
        } else {
            delete effectiveDelegates[key]
        }
    }

    Object.keys(effectiveLoadParams).forEach(givenKey => {
        if (!validLoadParams.includes(givenKey)) {
            reportError('loadParams', givenKey, `invalid load param, valid load parameters are: ${validLoadParams}`)
        }
    })

    Object.keys(effectiveDelegates).forEach(givenKey => {
        if (!validDelegates.includes(givenKey)) {
            reportError('delegates', givenKey, `invalid event delgate, valid event delegates are: ${validDelegates}`)
        }
    })

    const booleanKeys = ['showTypeSelector', 'showNpaSelector', 'showKeywordSearch', 'scrollToNewNumbers', 'allowMoreNumbers']
    booleanKeys.forEach(boolKey => {
        if (loadParams[boolKey] !== undefined && typeof loadParams[boolKey] !== 'boolean') {
            reportError('loadParams', boolKey, `must be given a boolean value (true or false), received: ${typeof loadParams[boolKey]}: ${loadParams[boolKey]}`)
        }
    })

    if (loadParams.type !== undefined) {
        const validTypes = ['local', 'toll-free']
        if (!validTypes.includes(loadParams.type)) {
            reportError('loadParams', 'type', `must be one of: ${validTypes}`)
        }
    }

    if (loadParams.npa !== undefined && loadParams.npa !== null) {
        effectiveLoadParams.npa = String(loadParams.npa)
        if (effectiveLoadParams.npa.length !== 3 || !(/^\d+$/).test(effectiveLoadParams.npa)) {
            reportError('loadParams', 'npa', 'must be a numeric value of exactly 3 digits')
        }
    }

    if (loadParams.keyword !== undefined) {
        effectiveLoadParams.keyword = loadParams.keyword.replace(/\W/g, '').substring(0, 7).toUpperCase()
        if (typeof effectiveLoadParams.keyword !== 'string' || effectiveLoadParams.keyword.length < 1) {
            reportError('loadParams', 'keyword', 'must be a string containing one or more alpha-numeric characters')
        }
    }

    if (loadParams.resultsQty !== undefined) {
        if (typeof loadParams.resultsQty !== 'number' || !Number.isInteger(loadParams.resultsQty) || loadParams.resultsQty < 1) {
            reportError('loadParams', 'resultsQty', `must be an integer value greater than 1, received: ${loadParams.resultsQty}`)
        }
    }

    return {
        effectiveLoadParams,
        effectiveDelegates
    }
}

window.pdcNumberPicker = {
    render: (renderId: string, loadParams: NumberPickerLoadParameters, delegates: NumberPickerEventDelegates) => {
        const container = getContainer(renderId)

        const { effectiveLoadParams, effectiveDelegates } = validateNumberPickerOptions(loadParams, delegates)

        ReactDOM.render(
            <ThemeProvider
                theme={theme}
                themeOverrides={loadParams.theme ? createThemeOverride(loadParams.theme) : {}}
            >
                <NumberPicker
                    loadParams={effectiveLoadParams}
                    delegates={effectiveDelegates}
                />
            </ThemeProvider>,
            container
        )
    },
    unmount: (renderId: string) => {
        const container = getContainer(renderId)
        ReactDOM.unmountComponentAtNode(container)
    }
}

// Example usage:  pdcNumberPicker.render({ renderId: 'number-picker' })
