import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import MuiSwitch from '@material-ui/core/Switch'
import Typography from 'typography'

const useStyles = makeStyles(theme =>
    createStyles({
        numberTypeFilter: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '4px',
            fontSize: '16px',
            fontWeight: 500,
            fontFamily: 'Montserrat, Helvetica, arial, sans-serif',
            lineHeight: 19,
            height: '24px'
        },
        label: {
            width: '100px',
            cursor: 'pointer'
        },
        inactiveLabel: {
            cursor: 'pointer'
        },
        activeLabel: {
            cursor: 'default'
        },
        switchTrack: {
            backgroundColor: () => `${theme.palette.primary.main} !important`
        }
    })
)

/**
 *
 */
export interface NumberTypeMap {
    [numberType: string]: boolean;
}

interface NumberTypeFilterProps {
  numberTypesEnabled: NumberTypeMap;
  onChange: (updatedNumberTypesEnabled: NumberTypeMap) => void;
}

/**
 *  Multi-select style filter allowing the user to choose which types of numbers to search for.
 */
const NumberTypeFilter = ({ numberTypesEnabled, onChange }: NumberTypeFilterProps): JSX.Element => {
    const checked = numberTypesEnabled.toll_free

    const classes = useStyles()

    const changeToLocal = () => {
        if (!numberTypesEnabled.local) {
            onChange({ local: true, toll_free: false }) // eslint-disable-line @typescript-eslint/naming-convention
        }
    }

    const changeToTollFree = () => {
        if (!numberTypesEnabled.toll_free) {
            onChange({ local: false, toll_free: true }) // eslint-disable-line @typescript-eslint/naming-convention
        }
    }

    const onSwitchChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        checked ? changeToTollFree() : changeToLocal()
    }

    return (
        <div data-testid="number-type-filter" className={classes.numberTypeFilter}>
            <Typography
                classes={{ root: `${classes.label} ${checked ? classes.inactiveLabel : classes.activeLabel}` }}
                align="right"
                variant="subtitle1"
                color="textPrimary"
                remoteConfigID="number_picker_number_type_local"
                onClick={changeToLocal}
            />

            <MuiSwitch
                classes={{
                    track: classes.switchTrack
                }}
                disableRipple
                checked={checked}
                onChange={onSwitchChanged}
            />

            <Typography
                classes={{ root: `${classes.label} ${checked ? classes.activeLabel : classes.inactiveLabel}` }}
                align="left"
                variant="subtitle1"
                color="textPrimary"
                remoteConfigID="number_picker_number_type_toll_free"
                onClick={changeToTollFree}
            />
        </div>
    )
}

export default NumberTypeFilter
