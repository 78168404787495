import React from 'react'
import ReactDOM from 'react-dom'
import NumberPicker from 'number-picker'
import { ThemeProvider } from 'providers'
import './index.css'
import { lighten } from '@material-ui/core'
import { PhoneNumberData } from 'phone-number'
import { theme } from 'get-theme'
const _ = require('lodash'); // eslint-disable-line

interface NumberPickerTheme {
    primaryColor: 'string';
    secondaryColor: 'string';
}
interface Options {
    renderId: string;
    theme?: NumberPickerTheme;
    delgates?: {
        onNumberClick: (phoneNumberData: PhoneNumberData) => void;
    }
}

const getContainer = (renderId: string) => {
    const container = document.getElementById(renderId)
    if (!container) {
        throw new Error(`pdcNumberPicker: can not find container element with ID: ${renderId}`)
    }
    return container
}

const createThemeOverride = (numberPickerTheme: NumberPickerTheme) => {
    const { primaryColor, secondaryColor } = numberPickerTheme

    return {
        palette: {
            primary: {
                main: primaryColor
            },
            info: {
                textDark: secondaryColor
            },
            action: {
                infoFocus: lighten(secondaryColor, 0.8)
            }
        },
        overrides: {
            MuiFormControl: {
                root: {
                    "& [class*='MuiInputBase-root']": {
                        '&.Mui-focused': {
                            '&:not(.Mui-error)': {
                                background: `${lighten(primaryColor, 0.9)} !important`
                            }
                        }
                    }
                }
            },
            MuiInputBase: {
                root: {
                    '&$focused:not($error)': {
                        backgroundColor: `${lighten(primaryColor, 0.9)} !important`
                    }
                }
            },
            MuiFilledInput: {
                root: {
                    '&:hover': {
                        backgroundColor: lighten(primaryColor, 0.9)
                    }
                },
                underline: {
                    '&::after': {
                        borderBottom: `1px solid ${primaryColor} !important`
                    }
                },
                adornedEnd: {
                    '& .adorned-end-x': {
                        '& svg': {
                            color: primaryColor
                        }
                    }
                }
            }
        }
    }
}

window.pdcNumberPicker = {
    render: (opts: Options) => {
        const container = getContainer(opts.renderId)
        ReactDOM.render(
            <ThemeProvider
                theme={theme}
                themeOverrides={opts.theme ? createThemeOverride(opts.theme) : {}}
            >
                <NumberPicker
                    onNumberClick={opts.delgates?.onNumberClick}
                />
            </ThemeProvider>,
            container
        )
    },
    unmount: (renderId: string) => {
        const container = getContainer(renderId)
        ReactDOM.unmountComponentAtNode(container)
    }
}

// Example usage:  pdcNumberPicker.render({ renderId: 'number-picker' })
