import React, { useEffect, useState, useCallback } from 'react'
import TextField from 'text-field-mui'
import { createStyles, makeStyles } from '@material-ui/core'
import { debounce } from 'debounce'

const useStyles = makeStyles(() =>
    createStyles({
        textFieldRoot: {
            textAlign: 'left',
            '& > div': {
                backgroundColor: 'transparent'
            }
        }
    })
)

interface KeywordSeachProps {
  initialKeyword: string;
  onChange: (keyword: string) => void;
  disabled: boolean;
}

/**
 * Filter component consisting of a text input where the user can type a keyword to filter
 * for numbers with digits that spell out the given keyword.
 */
const KeywordSearch = ({ initialKeyword, onChange, disabled }: KeywordSeachProps): JSX.Element => {
    const classes = useStyles()

    const [keyword, setKeyword] = useState(initialKeyword)

    const searchByKeywordDebounced = useCallback(
        debounce((updatedKeyword: string) => {
            onChange(updatedKeyword)
        }, 1000),
        [])

    useEffect(() => {
        searchByKeywordDebounced(keyword)
    }, [keyword])

    return (
        <TextField
            wrapInThemeProvider={false}
            data-testid="keyword-search"
            classNames={{ root: classes.textFieldRoot }}
            label="KEYWORD"
            value={keyword}
            onChange={event => {
                const newKeyword = event.target.value.replace(/[^0-9a-z*]/gi, '').slice(0, 7).toUpperCase()
                setKeyword(newKeyword)
            }}
            onXClick={() => setKeyword('')}
            disabled={disabled}
            fullWidth
        />
    )
}

export default KeywordSearch
