import React from 'react'
import { Autocomplete } from 'autocomplete-mui'
import Select from 'select-mui'
import { createStyles, makeStyles, MenuItem } from '@material-ui/core'
import { NumberTypeMap } from '../number-type-filter/NumberTypeFilter'
import { TextField } from 'text-field-mui'

const useStyles = makeStyles(() =>
    createStyles({
        areaCodeAutocomplete: {
            '& div div': {
                backgroundColor: 'transparent'
            },
            '& label': {
                textAlign: 'left'
            }
        },
        tollFreeSelector: {
            '& div': {
                backgroundColor: 'transparent'
            }
        }
    })
)

interface TollFreeAreaCodesFilterProps {
  loading: boolean;
  areaCodeOptions: string[];
  numberTypesEnabled: NumberTypeMap;
  selectedAreaCode: string | null;
  onChange: (areaCode: string | null) => void;
  disabled: boolean;
}

/**
 * Filter that allows the user to filter to and select one or more area codes to search for numbers.
 */
const AreaCodeFilter = ({ loading, areaCodeOptions, numberTypesEnabled, selectedAreaCode, onChange, disabled }: TollFreeAreaCodesFilterProps): JSX.Element => {
    const classes = useStyles()

    if (numberTypesEnabled.toll_free && !numberTypesEnabled.local) {
        const children = [
            <MenuItem key="8**" value="8**">
                8**
            </MenuItem>
        ].concat(areaCodeOptions.map(areaCode => (
            <MenuItem key={areaCode} value={areaCode}>
                {areaCode}
            </MenuItem>
        )))

        return (
            <Select
                data-testid="area-code-filter"
                label="PREFIX"
                value={selectedAreaCode || '8**'}
                onChange={event => {
                    const newAreaCode = event.target.value.replace(/\D/g, '').slice(0, 3) as string
                    onChange(newAreaCode === '8**' ? null : newAreaCode)
                }}
                formControlClasses={{
                    root: classes.tollFreeSelector
                }}
                disabled={disabled}
                fullWidth
            >
                {children}
            </Select>
        )
    }

    return (
        <Autocomplete
            key={numberTypesEnabled.toll_free ? 'toll-free-area-codes' : 'local-area-codes'}
            data-testid="area-code-filter"
            wrapInThemeProvider={false}
            label="AREA CODE"
            className={classes.areaCodeAutocomplete}
            loading={loading}
            options={areaCodeOptions}
            getOptionLabel={opt => opt}
            value={selectedAreaCode}
            onChange={(areaCode: string | null) => onChange(areaCode)}
            renderInput={params => {
                const onChange = e => {
                    const newValue = e.target.value.replace(/\D/g, '').slice(0, 3)
                    params.inputProps.onChange({
                        target: {
                            value: newValue
                        }
                    })
                }

                return (
                    <TextField
                        {...params}
                        fullWidth
                        wrapInThemeProvider={false}
                        label="AREA CODE"
                        hasFormValue={!!selectedAreaCode}
                        inputProps={{
                            ...params.inputProps,
                            onChange
                        }}
                    />
                )
            }}
            disabled={disabled}
            fullWidth
        />
    )
}

export default AreaCodeFilter
