import React, { useEffect, useState } from 'react'
import AreaCodeFilter from './search-filters/area-code-filter/AreaCodeFilter'
import NumberTypeFilter, { NumberTypeMap } from './search-filters/number-type-filter/NumberTypeFilter'
import KeywordFilter from './search-filters/keyword-filter/KeywordFilter'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Typography from 'typography'
import { lighten, darken } from '@material-ui/core/styles/colorManipulator'

const useStyles = makeStyles(theme => ({
    header: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 0,
        padding: '10px'
    },
    grid: {
        maxWidth: '464px',
        width: '100%'
    },
    oneTimeFeeNotice: {
        color: darken(theme.palette.primary.main, 0.2),
        backgroundColor: lighten(theme.palette.primary.main, 0.85),
        padding: '10px 15px',
        display: 'inline-block'
    }
}))

/**
 * Configuration applicable to all types of filters
 */
export interface FilterOptions {
    name: 'AreaCode' | 'NumberType' | 'Keyword'
}

/**
 * Represents a given set of values that can be used to
 * perform a number picker search. This format is independent of the
 * filterBy / request payload used by the number search APIs.
 */
export interface FilterValues {
    numberTypesEnabled: NumberTypeMap;
    selectedAreaCode: string | null;
    keyword: string;
}

interface HeaderProps {
    mode?: string;
    filters?: FilterOptions[];
    showResetButton?: boolean;
    fetchNumbers: (filterValues: FilterValues) => void;
    isSmallView: boolean;
}

/**
 * The header to the number picker that contains a row of search filters,
 * and a search bar displayed below the search filters.
 */
const Header = ({ filters, fetchNumbers, isSmallView }: HeaderProps): JSX.Element => {
    const classes = useStyles({ isSmallView })

    const [numberTypesEnabled, setNumberTypesEnabled] = useState<NumberTypeMap>({
        local: true,
        toll_free: false // eslint-disable-line @typescript-eslint/naming-convention
    })

    const [selectedAreaCode, setSelectedAreaCode] = useState<string | null>(null)

    const [keyword, setKeyword] = useState<string>('')

    useEffect(() => {
        fetchNumbers({
            numberTypesEnabled,
            selectedAreaCode,
            keyword
        })
    }, [JSON.stringify(numberTypesEnabled), selectedAreaCode, keyword])

    const filterComponents = {
        AreaCode: () => {
            return (
                <AreaCodeFilter
                    numberTypesEnabled={numberTypesEnabled}
                    selectedAreaCode={selectedAreaCode}
                    onChange={areaCode => setSelectedAreaCode(areaCode)}
                />
            )
        },
        NumberType: () => (
            <NumberTypeFilter
                numberTypesEnabled={numberTypesEnabled}
                onChange={numberTypeMap => {
                    setSelectedAreaCode(null)
                    setNumberTypesEnabled(numberTypeMap)
                }}
            />
        ),
        KeywordSearch: () => (
            <KeywordFilter
                onChange={newKeyword => setKeyword(newKeyword)}
            />
        )
    }

    const defaultFilters = [
        { name: 'NumberType', size: 12 },
        { name: 'AreaCode', size: 6 },
        { name: 'KeywordSearch', size: 6 }
    ]

    return (
        <div className={classes.header}>
            <Grid
                container
                spacing={3}
                className={classes.grid}
                direction="row"
                justifyContent="center"
                alignItems="center"
            >
                {(filters || defaultFilters).map((filter: FilterOptions) => (
                    <Grid
                        key={filter.name}
                        item
                        {...(isSmallView ? { xs: 12 } : { xs: filter.size || 12 })}
                    >
                        {filterComponents[filter.name](filter)}
                    </Grid>
                ))}

                <Grid item xs={12}>
                    <Typography
                        variant="body1"
                        color="primary"
                        className={classes.oneTimeFeeNotice}
                        remoteConfigID="number_picker_one_time_fees"
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default Header
