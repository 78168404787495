import React from 'react'
import Typography from 'typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    message: {
        color: `${theme.palette.error.main} !important`
    }
}))

interface Props {
    errorMessage: null | string | JSX.Element;
}

/**
 * UI for displaying any number picker related error.
 */
const ErrorMessage = (props: Props): null | JSX.Element => {
    const classes = useStyles()

    if (!props.errorMessage) return null

    return (
        <Typography variant="body1" className={classes.message}>
            {props.errorMessage}
        </Typography>
    )
}

export default ErrorMessage
