import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import MuiSwitch from '@material-ui/core/Switch'
import Typography from 'typography'

const useStyles = makeStyles(theme =>
    createStyles({
        numberTypeFilter: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '4px',
            fontSize: '16px',
            fontWeight: 500,
            fontFamily: 'Montserrat, Helvetica, arial, sans-serif',
            height: '24px'
        },
        label: {
            width: '100px'
        },
        clickable: {
            cursor: 'pointer'
        },
        switchTrack: {
            backgroundColor: ({ disabled }) => `${disabled ? theme.palette.secondary.main : theme.palette.primary.main} !important`
        }
    })
)

/**
 *
 */
export interface NumberTypeMap {
    [numberType: string]: boolean;
}

interface NumberTypeFilterProps {
  numberTypesEnabled: NumberTypeMap;
  onChange: (updatedNumberTypesEnabled: NumberTypeMap) => void;
  disabled: boolean;
}

/**
 *  Multi-select style filter allowing the user to choose which types of numbers to search for.
 */
const NumberTypeFilter = ({ numberTypesEnabled, onChange, disabled }: NumberTypeFilterProps): JSX.Element => {
    const checked = numberTypesEnabled.toll_free

    const classes = useStyles({ disabled })

    const changeNumberTypesEnabld = values => {
        if (disabled) return // Needed: since passsing diabled=true to the MuiSwitch doesn't seem to prevent onChange events
        onChange(values)
    }

    const changeToLocal = () => {
        if (!numberTypesEnabled.local) {
            changeNumberTypesEnabld({ local: true, toll_free: false }) // eslint-disable-line @typescript-eslint/naming-convention
        }
    }

    const changeToTollFree = () => {
        if (!numberTypesEnabled.toll_free) {
            changeNumberTypesEnabld({ local: false, toll_free: true }) // eslint-disable-line @typescript-eslint/naming-convention
        }
    }

    const onSwitchChanged = () => {
        checked ? changeToLocal() : changeToTollFree()
    }

    return (
        <div data-testid="number-type-filter" className={classes.numberTypeFilter}>
            <span onClick={changeToLocal} className={checked && !disabled ? classes.clickable : ''}>
                <Typography
                    classes={{ root: classes.label }}
                    align="right"
                    variant="subtitle1"
                    color="textPrimary"
                    remoteConfigID="number_picker_number_type_local"
                />
            </span>

            <span onClick={onSwitchChanged} className={classes.clickable}> {/* Increases the clickable area to include the entire MuiSwitch */}
                <MuiSwitch
                    classes={{
                        track: classes.switchTrack
                    }}
                    disableRipple
                    checked={checked}
                    onChange={onSwitchChanged}
                    disabled={disabled}
                />
            </span>

            <span onClick={changeToTollFree} className={!checked && !disabled ? classes.clickable : ''}>
                <Typography
                    classes={{ root: classes.label }}
                    align="left"
                    variant="subtitle1"
                    color="textPrimary"
                    remoteConfigID="number_picker_number_type_toll_free"
                />
            </span>
        </div>
    )
}

export default NumberTypeFilter
