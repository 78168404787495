/**
 *
 */
export class Teammate {
    public id = 0
    public first_name = ''
    public last_name = ''
    public email = ''
    public personal_phone_number = ''
    public voicemail_password = ''
    public timezone = ''
    public status = ''
    public is_admin = false
    public user_plan_id = 1
    public auth_center_id = 0
    public avatar_url = '0'
    public extension: Extension = new Extension()
    public plan: Plan = new Plan()
    public devices: any[] = []
    public numbers: Map<string, PhoneNumber> = new Map()

    /**
     *
     */
    public displayName = (): string => {
        return (this.first_name + ' ' + this.last_name).trim()
    }

    /**
     * @param json
     */
    public static fromJson (json: any): Teammate {
        const t = new Teammate()
        t.id = json.id
        t.last_name = json.last_name
        t.first_name = json.first_name
        t.email = json.email
        t.personal_phone_number = json.personal_phone_number
        t.voicemail_password = json.voicemail_password
        t.timezone = json.timezone
        t.status = json.status
        t.is_admin = json.is_admin
        t.user_plan_id = json.user_plan_id
        t.auth_center_id = json.auth_center_id
        t.avatar_url = json.avatar_url
        t.extension = Extension.fromJson(json.relationships.extension)
        t.plan = Plan.fromJson(json.relationships.plan)
        t.devices = json.relationships.devices.data
        t.numbers = PhoneNumber.fromJson(json.relationships.numbers)
        return t
    }

    /**
     *
     */
    public static fromJsonClassic (json: any): Teammate {
        const t = new Teammate()
        t.id = json.id
        if (json.name && json.name.split(' ').length > 1) {
            t.first_name = json.name.split(' ')[0]
            t.last_name = json.name.split(' ').slice(1).join(' ')
        } else {
            t.first_name = json.name
        }
        if (json?.voicemail?.notifications?.emails && json?.voicemail?.notifications?.emails.length > 0) {
            t.email = json?.voicemail?.notifications?.emails[0]?.email
        }
        t.personal_phone_number = json?.voicemail?.notifications?.sms
        t.voicemail_password = json.voicemail.password
        t.timezone = json.timezone
        t.extension = Extension.fromJsonClassic(json)
        return t
    }

    /**
     *
     */
    public toJson (): any {
        return {}
    }
}

/**
 *
 */
class Extension {
    public id = 0
    public number = 0
    public name = '0'
    public unlimited = false
    /**
     * @param json
     */
    public static fromJson (json: any): Extension {
        const data = json.data
        const e = new Extension()
        e.id = data.id
        e.number = data.extension_number
        e.name = data.extension_name
        e.unlimited = data.unlimited
        return e
    }

    public static fromJsonClassic (json: any): Extension {
        const data = json
        const e = new Extension()
        e.id = data.id
        e.number = data.extension
        e.name = data.name
        e.unlimited = data.unlimited
        return e
    }

    /**
     *
     */
    public toJson (): any {
        const json: any = {
            data: {}
        }
        json.data.id = this.id
        json.data.extension_number = this.number
        json.data.extension_name = this.name
        json.data.unlimited = this.unlimited
        return json
    }
}
class Plan {
    public id: number
    public description: string
    public billing_code: string | null
    public static fromJson (json: any): Plan {
        const data = json.data
        const p = new Plan()
        p.id = data.id
        p.description = data.plan_description
        p.billing_code = data.extension_billing_code
        return p
    }

    public toJson (): any {
        const json: any = {
            data: {}
        }
        json.data.id = this.id
        json.data.plan_description = this.description
        json.data.extension_billing_code = this.billing_code
        return json
    }
}
/**
 *
 */
class PhoneNumber {
    public id = 0
    public voip_did_id = 0
    public name = '0'
    public number = '0'
    public route_type = '0'
    /**
     * @param json
     */
    public static fromJson (json: any): Map<string, PhoneNumber> {
        const numbers = json.data
        let phonenumber: PhoneNumber
        const map = new Map<string, PhoneNumber>()
        numbers.forEach((n: any) => {
            phonenumber = new PhoneNumber()
            phonenumber.id = n.id
            phonenumber.voip_did_id = n.voip_did_id
            phonenumber.number = n.e164_number.toString()
            phonenumber.name = n.name
            phonenumber.route_type = n.route_type
            map.set(phonenumber.number, phonenumber)
        })
        return map
    }

    /**
     * @param {Map<string, PhoneNumber>} map
     */
    public static toJson (map: Map<string, PhoneNumber>): any {
        const json: any = {
            data: []
        }
        Array.from<string>(map.keys()).forEach(key => {
            const phonenumber: PhoneNumber = map.get(key)
            const n: any = {}
            n.id = phonenumber.id
            n.voip_did_id = phonenumber.voip_did_id
            n.e164_number = phonenumber.number
            n.name = phonenumber.name
            n.route_type = phonenumber.route_type
            json.data.append(n)
        })
        return json
    }
}
