import React, { useState, useEffect } from 'react'
import { Autocomplete } from 'autocomplete-mui'
import { createStyles, makeStyles } from '@material-ui/core'
import * as API from '../../API'
import { NumberTypeMap } from '../number-type-filter/NumberTypeFilter'

const useStyles = makeStyles(() =>
    createStyles({
        areaCodeAutocomplete: {
            '& div div': {
                backgroundColor: 'transparent'
            },
            '& label': {
                textAlign: 'left'
            }
        }
    })
)

interface TollFreeAreaCodesFilterProps {
  numberTypesEnabled: NumberTypeMap;
  selectedAreaCode: string | null;
  onChange: (areaCode: string | null) => void;
}

/**
 * Filter that allows the user to filter to and select one or more area codes to search for numbers.
 */
const AreaCodeFilter = ({ numberTypesEnabled, selectedAreaCode, onChange }: TollFreeAreaCodesFilterProps): JSX.Element => {
    const classes = useStyles()

    const [loadingAreaCodes, setLoadingAreaCodes] = useState<boolean>(false)
    const [localAreaCodes, setLocalAreaCodes] = useState<string[]>([])
    const tollFreeAreaCodes: string[] = ['800', '833', '844', '855', '866', '877', '888']

    const loadAreaCodes = async () => {
        setLoadingAreaCodes(true)
        const data = await API.fetchAreaCodes()
        setLocalAreaCodes(data)
        setLoadingAreaCodes(false)
    }

    useEffect(() => {
        loadAreaCodes()
    }, [])

    let options: string[] = []
    if (numberTypesEnabled.toll_free) {
        options = options.concat(tollFreeAreaCodes)
    }
    if (numberTypesEnabled.local) {
        options = options.concat(localAreaCodes)
    }
    return (
        <Autocomplete
            key={numberTypesEnabled.toll_free ? 'toll-free-area-codes' : 'local-area-codes'}
            data-testid="area-code-filter"
            wrapInThemeProvider={false}
            label={numberTypesEnabled.toll_free ? 'TOLL-FREE PREFIX' : 'AREA CODE'}
            className={classes.areaCodeAutocomplete}
            loading={loadingAreaCodes}
            options={options}
            getOptionLabel={opt => opt}
            value={selectedAreaCode}
            onChange={(areaCode: string | null) => onChange(areaCode)}
            fullWidth
        />
    )
}

export default AreaCodeFilter
