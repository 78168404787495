import React from 'react'
import { PhoneNumberData } from 'phone-number'

interface NumberReservationContextValue {
    reserveNumber: null | ((phoneNumber: PhoneNumberData) => Promise<void>);
    isReservingNumber: boolean;
    numberBeingReserved: null | PhoneNumberData;
    numbersTaken: PhoneNumberData[];
    error: null | Error;
    clearReservationError,
    reservedNumbers: PhoneNumberData[];
}

/**
 * Context to be provided to all number picker components that are interested in
 * starting the process of reserving a number and/or listening on the status of whether a number
 * is being reserved.
 */
const NumberReservationContext = React.createContext<NumberReservationContextValue>({
    reserveNumber: null,
    clearReservationError: null,
    numberBeingReserved: null,
    isReservingNumber: false,
    numbersTaken: [],
    error: null,
    reservedNumbers: []
})

export default NumberReservationContext
