import React, { useContext } from 'react'
import { createStyles, makeStyles, useTheme } from '@material-ui/core'
import { formatNumberWithAlphaMask } from 'phone-numbers'
import classNames from 'classnames'
import NumberReservationContext from '../number-reservation-context'
import Spinner from 'spinner'
import { lighten, darken } from '@material-ui/core/styles/colorManipulator'
import Typography from 'typography'

/**
 *
 */
export interface PhoneNumberData {
    country: string,
    format_mask: string, // eslint-disable-line @typescript-eslint/naming-convention
    local: boolean,
    number_search_result_id: string, // eslint-disable-line @typescript-eslint/naming-convention
    phone_number: string, // eslint-disable-line @typescript-eslint/naming-convention
    vanity_number?: string, // eslint-disable-line @typescript-eslint/naming-convention
    price: number,
    region: string,
    toll_free: boolean, // eslint-disable-line @typescript-eslint/naming-convention
    vendor_id: number // eslint-disable-line @typescript-eslint/naming-convention
}

interface PhoneNumberProps {
    phoneNumber: PhoneNumberData,
    isRecommended?: boolean;
    isSmallView?: boolean;
    onNumberClick?: (phoneNumberData: PhoneNumberData) => void;
}

const useStyles = makeStyles(theme =>
    createStyles({
        box: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',

            width: props => props.isSmallView ? 'unset' : '210px',
            height: props => props.isSmallView ? '94px' : '66px',

            '& svg': {
                width: props => props.isSmallView ? '30px' : 'unset',
                height: props => props.isSmallView ? '30px' : 'unset'
            },

            borderRadius: '3px',

            position: 'relative',
            fontSize: '1.2em',
            cursor: 'pointer',

            background: 'none',
            border: 'none',
            padding: '0 5px',
            outline: 'inherit',
            '&:focus': {
                outline: '1px dotted gray'
            }
        },
        numberTaken: {
            backgroundColor: theme.palette.error.background,
            color: theme.palette.error.main
        },
        shadowed: {
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)'
        },
        centralArea: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',

            width: '208px',
            height: '28px'
        },
        spinner: {
            width: '24px',
            height: '24px',
            marginRight: '10px',
            color: theme.palette.text.secondary
        },
        reservingNumberText: {
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '12px',
            color: theme.palette.text.secondary
        },
        reservingRecommendNumberText: {
            color: 'white'
        },
        numberText: {
            marginLeft: '10px',
            fontSize: props => props.isSmallView ? '20px' : '16px',
            display: 'flex',
            alignItems: 'center'
        },
        letters: {
            fontWeight: 600
        },
        priceTag: {
            borderRadius: '2px',
            color: darken(theme.palette.primary.main, 0.2),
            backgroundColor: lighten(theme.palette.primary.main, 0.85),
            zIndex: 1,
            position: 'absolute',
            top: '2px',
            right: '2px',
            padding: '0px 10px'
        },
        highlighted: {
            marginTop: '20px',
            marginBottom: '20px',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: '#3FAE29',
            color: 'white'
        },
        highlightedPriceTag: {
            color: 'white',
            backgroundColor: theme.palette.primary.textDark
        }
    })
)

// Custommized call icon with background
const CallIcon = ({ color, backgroundColor }: { color: string, backgroundColor: string }) => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="24" height="24" rx="12" fill={backgroundColor} fillOpacity="0.75"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.91328 11.3113C10.5632 12.7069 11.6482 13.8655 12.995 14.616L14.0982 13.5872C14.2336 13.4609 14.429 13.4241 14.5965 13.4882C15.133 13.6864 15.7159 13.8038 16.3167 13.8248C16.5832 13.8341 16.7937 14.0597 16.7844 14.3262L16.7253 16.0173C16.716 16.2838 16.4903 16.4943 16.2238 16.485C11.6739 16.3261 8.11521 12.5098 8.27409 7.95989C8.2834 7.69339 8.50906 7.48296 8.77557 7.49226L10.4715 7.55149C10.738 7.56079 10.9484 7.78646 10.9391 8.05296C10.918 8.65865 10.9946 9.2435 11.1549 9.79246C11.2023 9.96391 11.1569 10.1515 11.0165 10.2825L9.91328 11.3113Z" fill={color} />
    </svg>
)

// Customized X icon with a transclucent background
const RemoveIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#FF7A6B" fillOpacity="0.3"/>
        <path d="M16.2879 8.61856C16.405 8.5014 16.405 8.31145 16.2879 8.1943L15.8057 7.71213C15.6885 7.59497 15.4986 7.59497 15.3814 7.71213L12 11.0936L8.61856 7.71213C8.5014 7.59497 8.31145 7.59497 8.1943 7.71213L7.71213 8.1943C7.59497 8.31145 7.59497 8.5014 7.71213 8.61856L11.0936 12L7.71213 15.3814C7.59497 15.4986 7.59497 15.6885 7.71213 15.8057L8.1943 16.2879C8.31145 16.405 8.5014 16.405 8.61856 16.2879L12 12.9064L15.3814 16.2879C15.4986 16.405 15.6885 16.405 15.8057 16.2879L16.2879 15.8057C16.405 15.6885 16.405 15.4986 16.2879 15.3814L12.9064 12L16.2879 8.61856Z" fill="#E85646"/>
    </svg>
)

/**
 * Displays an individual number that the user can pick
 */
const PhoneNumber = ({ phoneNumber, isRecommended, isSmallView, onNumberClick }: PhoneNumberProps) => {
    const classes = useStyles({ isSmallView })
    const theme = useTheme()

    const { reserveNumber, isReservingNumber, numberBeingReserved, numbersTaken } = useContext(NumberReservationContext)

    const formattedNumber = formatNumberWithAlphaMask(phoneNumber.phone_number, phoneNumber.format_mask, { useAreaCodeBrackets: true })

    const onClick = () => {
        if (!isReservingNumber && reserveNumber) {
            reserveNumber(phoneNumber)
        } else if (onNumberClick) {
            onNumberClick(phoneNumber)
        }
    }

    // Renders the phone number so the letters are rendered bold/strong
    const renderPhoneNumber = (formattedNumber: string) => {
        const segments = []
        let word = formattedNumber[0]
        for (let i = 1; i < formattedNumber.length; i++) {
            if (formattedNumber[i].match(/[A-Z]/) === formattedNumber[i - 1].match(/[A-Z]/)) {
                word += formattedNumber[i] // previous char and current char are both letters or both non-letters
            } else {
                segments.push(word)
                word = formattedNumber[i]
            }
        }
        if (word) {
            segments.push(word)
        }
        return (
            <>
                {segments.map((seg, idx) => seg.match(/[A-Z]/) ? <span key={idx} className={classes.letters}>{seg}</span> : seg)}
            </>
        )
    }

    const isNumberTaken = numbersTaken.some((takenNumber: PhoneNumberData) => takenNumber.phone_number === phoneNumber.phone_number)

    const boxClasses = classNames(classes.box, {
        [`${classes.numberTaken}`]: isNumberTaken,
        [`${classes.highlighted}`]: isRecommended,
        [`${classes.shadowed}`]: !isRecommended
    })

    return (
        <button onClick={onClick} className={boxClasses}>
            {(isReservingNumber && numberBeingReserved?.phone_number === phoneNumber.phone_number)
                ? (
                    <>
                        <Spinner color={isRecommended ? 'white' : 'secondary'} className={classes.spinner} />
                        <span className={`${classes.reservingNumberText} ${isRecommended ? classes.reservingRecommendNumberText : ''}`}>
                        RESERVING NUMBER
                        </span>
                    </>
                )
                : (
                    <>
                        {phoneNumber.price && !isNumberTaken
                            ? (
                                <Typography
                                    variant="caption"
                                    color="primary"
                                    className={`${classes.priceTag} ${isRecommended && classes.highlightedPriceTag}`}
                                >
                                    {phoneNumber.price ? `$${parseFloat(`${phoneNumber.price}`).toLocaleString()}` : 'FREE'}
                                </Typography>
                            )
                            : null}

                        <>
                            {isNumberTaken
                                ? (
                                    <RemoveIcon />
                                )
                                : !isRecommended
                                    ? (
                                        <CallIcon
                                            color={theme.palette.info.textDark}
                                            backgroundColor={theme.palette.action.infoFocus}
                                        />
                                    )
                                    : null}

                            <Typography variant="subtitle1" className={classes.numberText}>
                                {renderPhoneNumber(formattedNumber)}
                            </Typography>
                        </>
                    </>
                )}
        </button>
    )
}

export default PhoneNumber
