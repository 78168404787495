import React, { useRef, useState } from 'react'
import Header, { FilterOptions, FilterValues } from './Header.tsx'
import NoResults from './NoResults.tsx'
import * as API from './API.ts'
import axios from 'axios'
import { PhoneNumberData } from '../phone-number/PhoneNumber'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Alert, Color as AlertColor } from 'alert-mui'
import Spinner from 'spinner'
import NumberList from './NumberList.tsx'
import useSize from '@react-hook/size'
import { useValue } from 'firebase-utils'

interface FilterBy {
    local?: boolean;
    toll_free?: boolean; // eslint-disable-line @typescript-eslint/naming-convention
    vanity?: boolean;

    is_like?: string; // eslint-disable-line @typescript-eslint/naming-convention
    contains?: string;

    area_code?: string; // eslint-disable-line @typescript-eslint/naming-convention

    price_min?: number; // eslint-disable-line @typescript-eslint/naming-convention
    price_max?: number; // eslint-disable-line @typescript-eslint/naming-convention
}

const useStyles = makeStyles(theme => ({
    numberPicker: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        textAlign: 'center'
    },
    results: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1
    },
    spinnerWrapper: {
        margin: '2em auto'
    }
}))

/**
 * Represents that data for a given number that the number picker exposes,
 * as part of it's partner-facing API.
 */
export interface NumberPickerNumberData {
    phone_number: string; // eslint-disable-line @typescript-eslint/naming-convention
    format_mask: string; // eslint-disable-line @typescript-eslint/naming-convention
    price: number;
    number_search_result_id: string; // eslint-disable-line @typescript-eslint/naming-convention
}

interface NumberPickerProps {
    filters?: FilterOptions[];
    onNumberClick?: (number: NumberPickerNumberData) => void;
}

const singleColumnBreakpoint = 500

/**
 *  Universal NumberPicker widget that allows the user to search and filter for a given number.
 *  Calls props.onNumberSelected() when the user clicks on a number.
 */
const NumberPicker = ({ filters, onNumberClick } : NumberPickerProps): JSX.Element => {
    const classes = useStyles()
    const theme = useTheme()

    const numberPickerRef = useRef(null)
    const [width] = useSize(numberPickerRef)

    const isSmallView = width < singleColumnBreakpoint

    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<null | string>(null)
    const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumberData[]>([])
    const requestCancelToken = useRef(null)

    const hotAreaCodePrices = JSON.parse(useValue('number_picker_hot_area_code_prices'))

    // Based on the given filter values, this returns an array of "FilterBy" structures, one for
    // each network request to be made to search-available-numbers
    // Specification: https://docs.google.com/document/d/1Qjbg3DemOaAj5mLI81szjCqAKbreC6zjKHOIdYIX5bI/edit
    const getRequestValues = (values: FilterValues): FilterBy[][] => {
        const formulateIsLikeMask = (areaCode: string | null, keyword): string => {
            let mask = `+1${areaCode || '...'}.......`
            if (keyword) {
                mask = mask.slice(0, mask.length - keyword.length)
                mask += keyword
            }
            mask = mask.replaceAll('*', '.') // Allow user to enter * as a placeholder/wildcard
            return mask
        }

        const getPriceMax = (areaCode: string | null) => {
            let priceMax = 0
            if (areaCode && areaCode in hotAreaCodePrices) {
                priceMax = hotAreaCodePrices[areaCode]
            }
            return priceMax
        }

        if (values.numberTypesEnabled.local && !values.selectedAreaCode && !values.keyword) { // Rule 1
            return [
                [{ local: true, price_max: getPriceMax(values.selectedAreaCode) }], // eslint-disable-line @typescript-eslint/naming-convention
                [{ local: true, price_min: 1 }] // eslint-disable-line @typescript-eslint/naming-convention
            ]
        } else if (values.numberTypesEnabled.toll_free && !values.selectedAreaCode && !values.keyword) { // Rule 2
            return [
                [{ toll_free: true, price_max: getPriceMax(values.selectedAreaCode) }], // eslint-disable-line @typescript-eslint/naming-convention
                [
                    { area_code: '800', price_max: getPriceMax('800') }, // eslint-disable-line @typescript-eslint/naming-convention
                    { area_code: '888', price_max: getPriceMax('888') }, // eslint-disable-line @typescript-eslint/naming-convention
                    { toll_free: true, price_min: 16 } // eslint-disable-line @typescript-eslint/naming-convention
                ]
            ]
        } else if (values.selectedAreaCode && !values.keyword) { // Rule 3 and 4
            return [
                [{ area_code: values.selectedAreaCode, price_max: getPriceMax(values.selectedAreaCode) }], // eslint-disable-line @typescript-eslint/naming-convention
                [{ area_code: values.selectedAreaCode, price_min: getPriceMax(values.selectedAreaCode) + 1 }] // eslint-disable-line @typescript-eslint/naming-convention
            ]
        } else if (values.numberTypesEnabled.local && !values.selectedAreaCode && values.keyword) { // Rule 5
            return [
                [{ local: true, is_like: formulateIsLikeMask(values.selectedAreaCode, values.keyword) }] // eslint-disable-line @typescript-eslint/naming-convention
            ]
        } else if (values.numberTypesEnabled.toll_free && !values.selectedAreaCode && values.keyword) { // Rule 6
            return [
                [{ toll_free: true, is_like: formulateIsLikeMask(values.selectedAreaCode, values.keyword) }] // eslint-disable-line @typescript-eslint/naming-convention
            ]
        } else if (values.selectedAreaCode && values.keyword) { // Rule 7 and 8
            return [
                [{ is_like: formulateIsLikeMask(values.selectedAreaCode, values.keyword) }] // eslint-disable-line @typescript-eslint/naming-convention
            ]
        } else {
            throw new Error('no logic defined to formulate requests based on the given filter values')
        }
    }

    /**
     * Organizes the sets of numbers into a single array so that they can be rendereed left to right,
     * top to bottom in an order suitable for display.
     *
     * @param columns A matrix where the outer array is the array of columns (left to right),
     * the second most outer array represents the the set of API requests for each column,
     * and the innermost array represents the list of numbers for a given API request.
     * @returns An array of PhoneNumberData in an order suitable for presentation
     */
    const sortNumbers = (columns: PhoneNumberData[][][]): PhoneNumberData[] => {
        const sortedNumbers: PhoneNumberData[] = []

        let columnIndex = 0
        const arrayIndices: number[] = columns.map(() => 0)
        while (columns.some(c => c.some(a => a.length > 0))) {
            if (columnIndex >= columns.length) {
                columnIndex = 0
            }
            const column = columns[columnIndex]
            if (column.some(a => a.length > 0)) {
                if (arrayIndices[columnIndex] >= column.length) {
                    arrayIndices[columnIndex] = 0
                }
                const array = column[arrayIndices[columnIndex]]
                if (array?.length) {
                    const nextNumber = array.pop()
                    if (nextNumber) {
                        sortedNumbers.push(nextNumber)
                    }
                }
                arrayIndices[columnIndex] += 1
            }
            columnIndex += 1
        }

        return sortedNumbers
    }

    const fetchNumbers = async (filterValues: FilterValues) => {
        if (requestCancelToken.current) {
            requestCancelToken.current.cancel() // Multiple requests can be cancelled with same cancel token
            requestCancelToken.current = null
        }

        const cancelToken = axios.CancelToken.source()
        requestCancelToken.current = cancelToken

        setLoading(true)
        setErrorMessage(null)

        const requestValues = getRequestValues(filterValues)
        const requestsPromise = Promise.all(requestValues.map(columnRequests => Promise.all(columnRequests.map(request => {
            return API.searchAvailableNumbers({ filter_by: request, limit: 50 }, cancelToken) // eslint-disable-line @typescript-eslint/naming-convention
        }))))

        let responseMatrix: undefined | API.SearchAvailableNumbersResponse[][]
        try {
            responseMatrix = await requestsPromise
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('call was canceled')
                return
            } else {
                setErrorMessage(error.message)
            }
        }

        if (responseMatrix) {
            const columns = responseMatrix.map(column => {
                return column.map(response => response.numbers).filter(r => r)
            })
            const sortedNumbers = sortNumbers(columns)
            setPhoneNumbers(sortedNumbers)
        }

        setLoading(false)
    }

    return (
        <div ref={numberPickerRef} className={classes.numberPicker}>
            <Header
                filters={filters}
                fetchNumbers={fetchNumbers}
                isSmallView={isSmallView}
            />

            <div className={classes.results}>
                {loading
                    ? (
                        <div className={classes.spinnerWrapper}>
                            <Spinner size="sm" color={theme.palette.primary.main} />
                        </div>
                    )
                    : errorMessage
                        ? (
                            <Alert
                                soft
                                color={AlertColor.ERROR}
                                content={errorMessage}
                            />
                        )
                        : phoneNumbers.length
                            ? (
                                <NumberList
                                    phoneNumbers={phoneNumbers}
                                    isSmallView={isSmallView}
                                    onNumberClick={onNumberClick}
                                />
                            )
                            : (
                                <NoResults />
                            )}
            </div>
        </div>
    )
}

export default NumberPicker
