import React, { useEffect, useState, useRef, useContext } from 'react'
import PhoneNumber, { PhoneNumberData } from 'phone-number'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'typography'
import { DownIcon } from 'svg-icons/src'
import { lighten, darken } from 'colors'
import { NumberResults, SelectedNumberMetaData } from './API'
import NoResults from './NoResults'
import NumberReservationContext from '../number-reservation-context'

const useStyles = makeStyles(theme => ({
    oneTimeFeeNotice: {
        marginTop: '10px',
        color: darken(theme.palette.primary.main, 0.2),
        backgroundColor: lighten(theme.palette.primary.main, 0.85),
        padding: '10px 15px',
        display: 'inline-block'
    },
    numberList: {
        display: 'flex',
        flexDirection: ({ isSmallView }) => isSmallView ? 'column' : 'row',
        justifyContent: ({ isSmallView }) => isSmallView ? 'stretch' : 'center',
        flexWrap: ({ isSmallView }) => isSmallView ? 'nowrap' : 'wrap',
        gap: '20px',
        margin: '20px 0',
        width: ({ isSmallView }) => isSmallView ? '90%' : 'unset',
        maxWidth: ({ isSmallView }) => isSmallView ? 'unset' : '440px'
    },
    column: {

    },
    moreNumbersButton: {
        marginTop: '25px',
        marginBottom: '20px',
        background: 'none',
        border: 'none',
        padding: '0 5px',
        outline: 'inherit',
        color: `${theme.palette.primary.main} !important`,
        cursor: 'pointer',
        '& span': {
            alignItems: 'center'
        },
        '&:focus': {
            outline: `1px dotted ${theme.palette.primary.main}`
        }
    }
}))

interface NumberSearchResultsProps {
    numberResults: NumberResults;
    pageSize: number;
    allowMoreNumbers: boolean;
    isSmallView: boolean;
    onNumberClick: (phoneNumber: PhoneNumberData, metaData: SelectedNumberMetaData) => void;
    numberBeingSelected: null | PhoneNumberData;
    scrollToNewNumbers?: boolean;
    numbersTaken: PhoneNumberData[];
}

/**
 * Displays a listing of numbers, organized into pages. The user can choose to reveal the next
 * page of numbers by clicking "More numbers".
 */
const NumberSearchResults = ({ numberResults, pageSize, allowMoreNumbers, isSmallView, onNumberClick, numberBeingSelected, scrollToNewNumbers, numbersTaken }: NumberSearchResultsProps): JSX.Element => {
    const classes = useStyles({ isSmallView })

    const numberReservationContextValue = useContext(NumberReservationContext)
    const numbersAlreadyReserved = numberReservationContextValue?.numbersTaken || []

    const allNumbersTaken = numbersTaken.concat(numbersAlreadyReserved)

    const numberListRef = useRef(null)

    const [revealedNumberCount, setRevealedNumberCount] = useState<number>(pageSize)
    const firstNumber = numberResults.cheap.length ? numberResults.cheap[0] : numberResults.expensive[0]
    const [numberToFocus, setNumberToFocus] = useState<null | string>(firstNumber ? firstNumber.e164 : null)

    const componentMounted = useRef<boolean>(false)

    // Merge into a single column so that on small screens they columns appear merged/interlaces and larger
    // screens they can spread out into rows of 2 (2 columns visually)
    const mergeNumbersIntoSingleColumn = () => {
        const a1 = numberResults.cheap
        const a2 = numberResults.expensive
        const l = Math.min(a1.length, a2.length)
        return [].concat(...Array.from({ length: l }, (unusedArg, i) => [a1[i], a2[i]]), a1.slice(l), a2.slice(l))
    }

    const mergedNumbers: PhoneNumberData[] = mergeNumbersIntoSingleColumn()

    const showMoreNumbers = event => {
        event.preventDefault()
        const number = mergedNumbers[revealedNumberCount]
        setNumberToFocus(number.e164)
        setRevealedNumberCount(revealedNumberCount + pageSize)
    }

    useEffect(() => {
        if (!componentMounted.current) {
            componentMounted.current = true
            return // Intentionally skip below focus/scrolling logic which we dont want on mount
        }

        if (!numberListRef.current) {
            return
        }

        const numberButtonToFocus = numberListRef.current.querySelector(`[data-testid="${numberToFocus}"]`)
        if (scrollToNewNumbers && numberButtonToFocus) {
            numberButtonToFocus.focus()
            numberButtonToFocus.scrollIntoView({ behavior: 'smooth' })
        }
    }, [numberToFocus])

    if (!numberResults.cheap.length && !numberResults.expensive.length) {
        return (
            <NoResults />
        )
    }

    return (
        <>
            <Typography
                variant="body1"
                color="primary"
                className={classes.oneTimeFeeNotice}
                remoteConfigID="number_picker_one_time_fees"
            />

            <div ref={numberListRef} className={classes.numberList}>
                {mergedNumbers.slice(0, revealedNumberCount).map((phoneNumber: PhoneNumberData, index) => (
                    <PhoneNumber
                        testId={phoneNumber.e164}
                        key={index}
                        phoneNumber={phoneNumber}
                        isSmallView={isSmallView}
                        onClick={() => onNumberClick(phoneNumber, { ui_recommended: false, ui_index: index })} // eslint-disable-line @typescript-eslint/naming-convention
                        numberBeingSelected={numberBeingSelected}
                        isNumberTaken={allNumbersTaken.some((takenNumber: PhoneNumberData) => takenNumber.e164 === phoneNumber.e164)}
                    />
                ))}
            </div>

            {allowMoreNumbers && (revealedNumberCount < mergedNumbers.length) &&
                <button onClick={showMoreNumbers} className={classes.moreNumbersButton} disabled={numberBeingSelected !== null}>
                    <Typography variant="body1" display="flex">
                        More numbers <DownIcon />
                    </Typography>
                </button>
            }
        </>
    )
}

export default NumberSearchResults
