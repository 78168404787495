import React, { useState } from 'react'
import PhoneNumber, { PhoneNumberData } from '../phone-number/PhoneNumber'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'typography'
import { DownIcon } from 'svg-icons/src'
import { NumberPickerNumberData } from './NumberPicker'

const useStyles = makeStyles(theme => ({
    numberList: {
        display: 'flex',
        flexDirection: ({ isSmallView }) => isSmallView ? 'column' : 'row',
        justifyContent: ({ isSmallView }) => isSmallView ? 'stretch' : 'center',
        flexWrap: ({ isSmallView }) => isSmallView ? 'nowrap' : 'wrap',
        gap: '20px',
        margin: '20px 0',
        width: ({ isSmallView }) => isSmallView ? '90%' : 'unset',
        maxWidth: ({ isSmallView }) => isSmallView ? 'unset' : '440px'
    },
    moreNumbersButton: {
        marginTop: '25px',
        marginBottom: '20px',
        background: 'none',
        border: 'none',
        padding: '0 5px',
        outline: 'inherit',
        color: `${theme.palette.primary.main} !important`,
        cursor: 'pointer',
        '& span': {
            alignItems: 'center'
        },
        '&:focus': {
            outline: `1px dotted ${theme.palette.primary.main}`
        }
    }
}))

interface NumberListProps {
    phoneNumbers: PhoneNumberData[];
    isSmallView: boolean;
    onNumberClick?: (numberData: NumberPickerNumberData) => void;
}

const pageSize = 8

/**
 * Displays a listing of numbers, organized into pages. The user can choose to reveal the next
 * page of numbers by clicking "More numbers".
 */
const NumberList = ({ phoneNumbers, isSmallView, onNumberClick }: NumberListProps): JSX.Element => {
    const classes = useStyles({ isSmallView })

    const [revealedNumberCount, setRevealedNumberCount] = useState<number>(pageSize)

    const showMoreNumbers = event => {
        event.preventDefault()
        setRevealedNumberCount(revealedNumberCount + pageSize)
    }

    return (
        <>
            <div className={classes.numberList}>
                {phoneNumbers.slice(0, revealedNumberCount).map((phoneNumber: PhoneNumberData, index) => (
                    <PhoneNumber
                        key={index}
                        phoneNumber={phoneNumber}
                        isSmallView={isSmallView}
                        onNumberClick={(p: PhoneNumberData) => onNumberClick?.({
                            number_search_result_id: p.number_search_result_id, // eslint-disable-line @typescript-eslint/naming-convention
                            phone_number: p.phone_number, // eslint-disable-line @typescript-eslint/naming-convention
                            format_mask: p.format_mask, // eslint-disable-line @typescript-eslint/naming-convention
                            price: p.price * 100
                        })}
                    />
                ))}
            </div>

            {revealedNumberCount < phoneNumbers.length &&
                <button onClick={showMoreNumbers} className={classes.moreNumbersButton}>
                    <Typography variant="body1" display="flex">
                        More numbers <DownIcon />
                    </Typography>
                </button>
            }
        </>
    )
}

export default NumberList
