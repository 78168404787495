import React, { useContext, useEffect, useRef, useState } from 'react'
import Header, { FilterOptions, FilterValues } from './Header.tsx'
import * as API from './API.ts'
import axios, { CancelTokenSource } from 'axios'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Spinner from 'spinner'
import NumberSearchResults from './NumberSearchResults.tsx'
import useSize from '@react-hook/size'
import ErrorMessage from './ErrorMessage'
import RecommendedNumberSection from './RecommendedNumberSection'
import NumberReservationContext from 'number-reservation-context'
import { PhoneNumberData } from 'phone-number'
import { useValue } from 'firebase-utils'
import JSCookie from 'js-cookie'

const useStyles = makeStyles(theme => ({
    numberPicker: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        textAlign: 'center'
    },
    results: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1
    },
    spinnerWrapper: {
        margin: '2em auto'
    }
}))

/**
 * Represents that data for a given number that the number picker exposes,
 * as part of it's partner-facing API.
 */
export interface NumberPickerNumberData {
    number_picker_session_token: string; // eslint-disable-line @typescript-eslint/naming-convention
    number_e164: string; // eslint-disable-line @typescript-eslint/naming-convention
    number_price: number; // eslint-disable-line @typescript-eslint/naming-convention
    number_mask?: string; // eslint-disable-line @typescript-eslint/naming-convention
}

/**
 *
 */
export interface NumberPickerEventDelegates {
    onNumberSelected?: (number: NumberPickerNumberData) => void;
}

/**
 * Props for the NumberPicker component, including params plus initial
 * data
 */
export interface NumberPickerProps extends NumberPickerEventDelegates {
    initData: API.InitResponseData;
    loadParams: API.NumberPickerLoadParameters;
    delegates: NumberPickerEventDelegates;
    errorMessage?: string | null;
}

const singleColumnBreakpoint = 500

/**
 *  Universal NumberPicker widget that allows the user to search and filter for a given number.
 *  Calls props.onNumberSelected() when the user clicks on a number.
 */
const NumberPicker = (props: NumberPickerProps): JSX.Element => {
    const classes = useStyles()
    const theme = useTheme()

    // Note: this context is optionally provided: only when handling reservations is required.
    const reservationContextValue = useContext(NumberReservationContext)

    const errorMessages = JSON.parse(useValue('number_picker_error_messages'))

    const [initialFetchSkipped, setInitialFetchSkipped] = useState<boolean>(false)

    const numberPickerRef = useRef(null)
    const [width] = useSize(numberPickerRef)

    const isSmallView = width < singleColumnBreakpoint

    const [loading, setLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<null | string>(props.errorMessage ? errorMessages[props.errorMessage] : null)
    const [numberResults, setNumberResults] = useState<null | API.NumberResults>(props.initData?.numbers || null)
    const requestCancelToken = useRef<null | CancelTokenSource>(null)

    const [numberBeingSelected, setNumberBeingSelected] = useState<null | PhoneNumberData>(null)

    // Numbers found to be not available after an attempt to select it using the select-a-number API
    const [numbersTaken, setNumbersTaken] = useState<PhoneNumberData[]>([])

    const [searchStarted, setSearchStarted] = useState<boolean>(typeof props.loadParams.searchOnLoad === 'undefined' ? true : props.loadParams.searchOnLoad)

    useEffect(() => {
        if (reservationContextValue.error) {
            setErrorMessage(reservationContextValue.error.message)
        }
    }, [reservationContextValue?.error])

    const fetchNumbers = async (filterValues: FilterValues) => {
        if (!initialFetchSkipped) {
            setInitialFetchSkipped(true)
            return // Skip fetchNumbers() on mount as that is taken care of through the API.init()
        }

        if (requestCancelToken.current) {
            requestCancelToken.current.cancel('Cancelling previous number picker search, as a new search with potentially different params is starting')
            requestCancelToken.current = null
        }

        const cancelToken = axios.CancelToken.source()
        requestCancelToken.current = cancelToken

        setLoading(true)
        setErrorMessage(null)
        if (typeof reservationContextValue.clearReservationError === 'function') {
            reservationContextValue.clearReservationError()
        }
        setSearchStarted(true)

        const searchParams: API.SearchParameters = {
            type: filterValues.numberTypesEnabled.local ? 'local' : 'toll-free',
            npa: filterValues.selectedAreaCode,
            keyword: filterValues.keyword
        }

        try {
            const responseData = await API.search(searchParams, requestCancelToken.current)

            const newNumberResults = JSON.parse(JSON.stringify(responseData.numbers))
            if (!newNumberResults.recommended && numberResults?.recommended) {
                // Carry forward the recommended number, when we previously had a recommended number,
                // but the new search results does not include one.
                newNumberResults.recommended = numberResults?.recommended
            }

            setNumberResults(newNumberResults)
        } catch (error) {
            const errorMessage = (error as Error).message

            if (axios.isCancel(error)) {
                console.debug(`NumberPicker search request cancelled, reason: ${errorMessage}`)
                return // Keep loading flag set to true
            }

            setErrorMessage(errorMessage)
        }

        setLoading(false)
    }

    const onNumberClick = async (phoneNumber: PhoneNumberData, metaData: API.SelectedNumberMetaData) => {
        setErrorMessage(null)
        setNumberBeingSelected(phoneNumber)

        const handleError = (error) => {
            console.error('NumberPicker: error during number selection: ', error)
            if (error.message === 'number_not_available') {
                setNumbersTaken([...numbersTaken, phoneNumber])
            }
            const userFacingMessage = errorMessages[error.message] || errorMessages.default
            setErrorMessage(userFacingMessage)
            setNumberBeingSelected(null)
        }

        // For analytics and to check if the number still exists with the carrier use the numberSelected API:
        let data
        try {
            data = await API.selectANumber(phoneNumber, metaData)
        } catch (error) {
            handleError(error)
        }

        if (data?.status === 'success') {
            // (Optional) If a reservation context is available, perform the extra step of trying
            // to reserve the number. (can be used to put the number in a shopping cart, etc.)
            if (typeof reservationContextValue.reserveNumber === 'function') {
                try {
                    await reservationContextValue.reserveNumber(phoneNumber)
                } catch (error) {
                    handleError(error)
                }
            }

            if ('onNumberSelected' in props.delegates) {
                const args: NumberPickerNumberData = {
                    number_picker_session_token: JSCookie.get('pdc_number_picker_session_token'),
                    number_e164: phoneNumber.e164,
                    number_price: phoneNumber.price
                }
                if (phoneNumber.mask) {
                    args.number_mask = phoneNumber.mask
                }
                props.delegates?.onNumberSelected?.(args)
            }
        }

        setNumberBeingSelected(null)
    }

    // Allows for control over the size, order and configuration of filters
    const filters: FilterOptions[] = []
    if (props.loadParams.showTypeSelector) {
        filters.push({ name: 'NumberType', size: 12 })
    }
    if (props.loadParams.showNpaSelector) {
        filters.push({ name: 'AreaCode', size: props.loadParams.showKeywordSearch ? 6 : 12 })
    }
    if (props.loadParams.showKeywordSearch) {
        filters.push({ name: 'KeywordSearch', size: props.loadParams.showNpaSelector ? 6 : 12 })
    }

    let initialAreaCode = props.loadParams.npa || null
    if (props.loadParams.type !== 'toll-free' && props.initData?.suggested_npa) {
        initialAreaCode = props.initData?.suggested_npa
    }

    return (
        <div data-testid="number-picker" ref={numberPickerRef} className={classes.numberPicker}>
            <ErrorMessage errorMessage={errorMessage} />

            {props.loadParams.recommendANumber && numberResults?.recommended
                ? <RecommendedNumberSection
                    phoneNumber={numberResults.recommended}
                    numberBeingSelected={numberBeingSelected}
                    onNumberClick={() => onNumberClick(numberResults.recommended, { ui_recommended: true })} // eslint-disable-line @typescript-eslint/naming-convention
                />
                : null}

            <Header
                initData={props.initData}
                initialNumberType={props.loadParams.type || 'local'}
                initialAreaCode={initialAreaCode}
                initialKeyword={props.loadParams.keyword || ''}
                filters={filters}
                fetchNumbers={fetchNumbers}
                isSmallView={isSmallView}
                disabled={numberBeingSelected !== null}
            />

            {(loading || errorMessage || numberResults != null) &&
                <div className={classes.results}>
                    {loading
                        ? (
                            <div className={classes.spinnerWrapper}>
                                <Spinner size="sm" color={theme.palette.primary.main} />
                            </div>
                        )
                        : numberResults && searchStarted
                            ? (
                                <NumberSearchResults
                                    numberResults={numberResults}
                                    isSmallView={isSmallView}
                                    onNumberClick={onNumberClick}
                                    numberBeingSelected={numberBeingSelected}
                                    pageSize={props.loadParams.resultsQty}
                                    allowMoreNumbers={props.loadParams.allowMoreNumbers}
                                    scrollToNewNumbers={props.loadParams.scrollToNewNumbers}
                                    numbersTaken={numbersTaken}
                                />
                            )
                            : null}
                </div>
            }
        </div>
    )
}

NumberPicker.defaultProps = {
    loadParams: {
        recommendANumber: true,

        type: 'local',
        showTypeSelector: true,

        npa: null,
        showNpaSelector: true,

        keyword: null,
        showKeywordSearch: true,

        searchOnLoad: true,

        scrollToNewNumbers: true,

        resultsQty: 8,
        allowMoreNumbers: true,

        errorMessage: null
    },
    delegates: {
    }
}

export default NumberPicker
