import React from 'react'
import PhoneNumber, { PhoneNumberData } from 'phone-number'
import Typography from 'typography'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => createStyles({
    recommendedNumberSection: {
        maxWidth: 440,
        margin: '10px auto'
    }
}))

interface Props {
    phoneNumber: PhoneNumberData;
    numberBeingSelected: PhoneNumberData | null;
    onNumberClick: () => void;
}

/**
 * Prominently displays a particular number, recommending it.
 */
const RecommendedNumberSection = (props: Props): JSX.Element => {
    const classes = useStyles()

    return (
        <div className={classes.recommendedNumberSection}>
            <Typography
                variant="body2"
                remoteConfigID="number_picker_number_recommendation"
            />

            <PhoneNumber
                testId={props.phoneNumber.e164}
                isRecommended={true}
                phoneNumber={props.phoneNumber}
                numberBeingSelected={props.numberBeingSelected}
                onClick={() => props.onNumberClick()}
            />

            <Typography
                variant="body2"
                remoteConfigID="number_picker_number_recommendation_search_instructions"
            />
        </div>
    )
}

export default RecommendedNumberSection
