import axios, { AxiosRequestConfig } from 'axios'
import { PhoneNumberData } from 'phone-number'
import JSCookie from 'js-cookie'

const sessionTokenCookieName = 'pdc_number_picker_session_token'

const callAPI = (apiFunctionName: string, params, config: AxiosRequestConfig = {}) => {
    return axios.post(
        `https://${process.env.REACT_APP_API_HOST}/number-picker/${apiFunctionName}`,
        {
            number_picker_session_token: JSCookie.get(sessionTokenCookieName), // eslint-disable-line @typescript-eslint/naming-convention
            number_picker_url: `${window.location}`, // eslint-disable-line @typescript-eslint/naming-convention
            ...params
        },
        config
    ).then(response => {
        const { data } = response

        if (data.status === 'error') {
            throw new Error(data.details)
        } else if (data.error) {
            throw new Error(data.error.message)
        }

        return data
    })
}

/**
 * Load parameters that can control how the number picker is to be loaded/initiazed.
 */
export interface NumberPickerLoadParameters {
    showTypeSelector?: boolean,
    showNpaSelector?: boolean,
    showKeywordSearch?: boolean,

    recommendANumber?: boolean,

    type?: string;
    npa?: string | null;
    keyword?: string;

    searchOnLoad?: boolean,

    resultsQty?: number;
    allowMoreNumbers?: boolean;
    scrollToNewNumbers?: boolean,
}

/**
 * JSON data to be sent in request body to requests to the number-picker/init endpoint.
 */
export interface InitRequestData {
    number_picker_session_token?: string; // eslint-disable-line @typescript-eslint/naming-convention
    number_picker_url: string; // eslint-disable-line @typescript-eslint/naming-convention
    number_picker_load_parameters: NumberPickerLoadParameters // eslint-disable-line @typescript-eslint/naming-convention
}

/**
 *
 */
export interface NumberResults {
    recommended?: PhoneNumberData;
    cheap: PhoneNumberData[];
    expensive: PhoneNumberData[];
}

/**
 * JSON data in response from a request to the number-picker/init endpoint.
 */
export interface InitResponseData {
    number_picker_session_token: string; // eslint-disable-line @typescript-eslint/naming-convention
    npas: {
        local: string[];
        toll_free: string[]; // eslint-disable-line @typescript-eslint/naming-convention
    };
    suggested_npa: string; // eslint-disable-line @typescript-eslint/naming-convention
    numbers: NumberResults
}

/**
 * Endpoint used to initialize the number picker.
 */
export const init = async (loadParams: NumberPickerLoadParameters): Promise<InitResponseData> => {
    return callAPI('init', {
        number_picker_load_parameters: loadParams // eslint-disable-line @typescript-eslint/naming-convention
    }).then(data => {
        JSCookie.set(sessionTokenCookieName, data.number_picker_session_token, {})
        return data
    })
}

/**
 * Parameters that can be used to filter the set of numbers in a number search.
 */
export interface SearchParameters {
    type: 'local' | 'toll-free';
    npa: string | null;
    keyword: string;
    resultsQty?: number; // only send when allowMoreNumbers === false
}

interface SearchResponseData {
    number_picker_session_token: string; // eslint-disable-line @typescript-eslint/naming-convention
    numbers: NumberResults
}

/**
 * Endpoint used to search/fetch numbers meeting the specified parameters.
 */
export const search = async (searchParams: SearchParameters, cancelToken): Promise<SearchResponseData> => {
    return callAPI('search', {
        search_parameters: searchParams // eslint-disable-line @typescript-eslint/naming-convention
    }, {
        cancelToken: cancelToken.token
    })
}

/**
 * Info about where the number being selected originated from in the user interface.
 */
export interface SelectedNumberMetaData {
    ui_recommended: boolean; // eslint-disable-line @typescript-eslint/naming-convention
    ui_index?: number; // eslint-disable-line @typescript-eslint/naming-convention
}

/**
 * JSON data in response from a request to the number-picker/select-a-number endpoint.
 */
export interface SelectANumberResponse {
    number_picker_session_token: string; // eslint-disable-line @typescript-eslint/naming-convention
    number_e164: string; // eslint-disable-line @typescript-eslint/naming-convention
    number_mask: string; // eslint-disable-line @typescript-eslint/naming-convention
    status: string;
    details: string;
}

/**
 * Endpoint used to select a number, checking its availability with the carrier and reporting
 * analytical data at the same time.
 */
export const selectANumber = async (phoneNumber: PhoneNumberData, metaData: SelectedNumberMetaData): Promise<SelectANumberResponse> => {
    return callAPI('select-a-number', {
        number_e164: phoneNumber.e164, // eslint-disable-line @typescript-eslint/naming-convention
        number_mask: phoneNumber.mask, // eslint-disable-line @typescript-eslint/naming-convention
        ...metaData
    })
}
