import React, { useCallback, useEffect, useState } from 'react'
import TextField from 'text-field-mui'
import { debounce } from 'debounce'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
    createStyles({
        textFieldRoot: {
            textAlign: 'left',
            '& > div': {
                backgroundColor: 'transparent'
            }
        }
    })
)

interface KeywordSeachProps {
  onChange: (keyword: string) => void;
}

/**
 * Filter component consisting of a text input where the user can type a keyword to filter
 * for numbers with digits that spell out the given keyword.
 */
const KeywordSearch = ({ onChange }: KeywordSeachProps): JSX.Element => {
    const classes = useStyles()

    const [keyword, setKeyword] = useState('')

    const searchByKeywordDebounced = useCallback(
        debounce((updatedKeyword: string) => {
            onChange(updatedKeyword)
        }, 500),
        [])

    useEffect(() => {
        searchByKeywordDebounced(keyword)
    }, [keyword])

    return (
        <TextField
            wrapInThemeProvider={false}
            data-testid="keyword-search"
            className={classes.textFieldRoot}
            classes={{ root: classes.textFieldRoot }}
            label="SEARCH"
            value={keyword}
            onChange={event => {
                const newKeyword = event.target.value.trim().slice(0, 7).toUpperCase()
                if (/[^0-9A-Z*]/.test(newKeyword)) {
                    return
                }
                setKeyword(newKeyword)
            }}
            onXClick={() => setKeyword('')}
            fullWidth
        />
    )
}

export default KeywordSearch
