import React, { useState, useEffect } from 'react'
import * as API from './API.ts'
import NumberPicker, { NumberPickerEventDelegates } from './NumberPicker.tsx'
import Typography from 'typography'
import Spinner from 'spinner'
import ErrorMessage from './ErrorMessage'

interface NumberPickerInitializerProps {
    loadParams: API.NumberPickerLoadParameters;
    delegates: NumberPickerEventDelegates;
    errorMessage?: string | null;
}

/**
 * Top-level, initialization component for the number picker functional component.
 */
const NumberPickerInitializer = (props: NumberPickerInitializerProps) => {
    const [initData, setInitData] = useState<null | API.InitResponseData>(null)
    const [error, setError] = useState<null | Error>(null)

    const initialize = async () => {
        setError(null) // clear any error (in the case where we are trying again)
        setInitData(null)
        try {
            const responseData = await API.init(props.loadParams)
            setInitData(responseData)
        } catch (error) {
            console.error('NumberPicker: an error occured while trying to initialize: ', error)
            setError(error as Error)
        }
    }

    useEffect(() => {
        initialize()
    }, [])

    if (error) {
        return (
            <ErrorMessage
                errorMessage={
                    <>
                        <Typography remoteConfigID='number_picker_init_error' />
                        <Typography component="a" remoteConfigID='number_picker_init_try_again' onClick={initialize} />
                    </>
                }
            />
        )
    }

    if (!initData) {
        return <Spinner />
    }

    return (
        <NumberPicker
            initData={initData}
            loadParams={props.loadParams}
            delegates={props.delegates}
            errorMessage={props.errorMessage}
        />
    )
}

export default NumberPickerInitializer
