/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext, useContext, PropsWithChildren, useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { createSlug } from 'helpers'
import { ItemType } from 'widgets/src/components/Item'
import { getValue } from 'firebase-utils'
import { IconNameType } from 'foundations-library/types'
import { shuffleArray } from 'helpers'

const checkIfRouteMatches = (pathname, str) => {
    // todo find a better way to do this, it is super messy

    const cleanedPathName = decodeURIComponent(pathname).replace(/ /g, '').toLowerCase()
    const cleanedStr = decodeURIComponent(str).replace(/ /g, '').toLowerCase()

    const pathArray = cleanedPathName.split('/')
    const strArray = cleanedStr.split('/')

    let isMatch = true
    // loop through path and check if it matches the string skipping * values
    if (pathArray.length !== strArray.length) isMatch = false
    pathArray.forEach((x, i) => {
        if (strArray[i] !== '*' && x !== strArray[i]) {
            isMatch = false
        }
    })

    return isMatch
}

const reorderArticles = (articles, pathname) => {
    if (!pathname || !articles?.length) return []
    let preferred = []
    let defaultArticles = []
    for (const article of articles) {
        if (article?.preferredRoutes?.find((preferredRoute) => checkIfRouteMatches(pathname, preferredRoute))) {
            preferred.push(article)
        } else {
            defaultArticles.push(article)
        }
    }
    if (preferred.length) preferred = shuffleArray(preferred)
    if (defaultArticles.length) defaultArticles = shuffleArray(defaultArticles)

    return [...preferred, ...defaultArticles]
}

const formatSectionData = (data, pathname = '') => {
    const articles = pathname ? reorderArticles(data.articles, pathname) : (data.articles || [])
    const defaultSectionName = 'Articles'
    const defaultData = {
        sectionName: defaultSectionName,
        viewAllButtonText: 'View all articles',
        slug: data?.slug || createSlug(data?.sectionName || defaultSectionName),
        maxWidth: '1050px',
        icon: 'cellular',
        articlesTitle: 'Top How-to-articles'
    }
    const newData = {
        ...defaultData,
        ...data,
        articles
    }
    newData.articles = newData.articles.map((x) => {
        x.slug = x.slug || createSlug(x.title)
        return x
    })

    return newData
}
/**
 *
 */
export type WidgetArticleType = ItemType
/**
 *
 */
export type WidgetSectionDataType = {
    articles: WidgetArticleType[],
    sectionName: string,
    viewAllButtonText: string,
    maxWidth?: string,
    icon?: IconNameType,
    articlesTitle?: string,
    slug: string;
}

type WidgetContextDataType = {
    pageBasedData: WidgetSectionDataType,
    findArticleBySlugs(sectionSlug: string, articleSlug: string): WidgetArticleType | null,
    remoteValue: { sections: WidgetSectionDataType[] },
    getArticlesBySection(v: string): WidgetSectionDataType
    findSectionBySlug(v): WidgetSectionDataType
}

const WidgetContext = createContext<WidgetContextDataType | undefined>(undefined)

const emptyValues = {
    articles: [],
    sectionName: '',
    viewAllButtonText: '',
    slug: ''
}

/**
 *
 */
const WidgetProvider = ({ children }: PropsWithChildren<any>): JSX.Element => {
    const location = useLocation()
    const [pageBasedData, _setPageBasedData] = useState({ ...emptyValues })

    const remoteValue = useMemo((): { sections: WidgetSectionDataType[] } => {
        const data = JSON.parse(getValue('widgets'))
        data.sections = data.sections.map((x) => formatSectionData(x))

        return data
    }, [])

    const getArticlesBySection = (sectionName) => {
        const section = remoteValue.sections.find((x) => x.sectionName === sectionName)
        if (!section) return null
        return formatSectionData(section)
    }

    const setPageBasedData = (sectionData, pathname = '') => {
        _setPageBasedData(formatSectionData(sectionData, pathname))
    }

    useEffect(() => {
        let section
        if (!checkIfRouteMatches(location.pathname, '/support-center')) {
            section = remoteValue.sections.find((x) => x.routes.find((y) => checkIfRouteMatches(location.pathname + location.hash, y)))
        }
        if (!section) {
            setPageBasedData({ ...emptyValues })
        } else {
            setPageBasedData(section, location.pathname)
        }
    }, [remoteValue, location])

    const findSectionBySlug = (slug) => remoteValue.sections.find((x) => x.slug === slug)

    const findArticleBySlugs = (sectionSlug, articleSlug) => {
        const section = findSectionBySlug(sectionSlug)
        if (!section) return null

        const article = section.articles.find((x) => x.slug === articleSlug)
        return article || null
    }

    return (
        <WidgetContext.Provider value={{
            pageBasedData,
            findArticleBySlugs,
            remoteValue,
            getArticlesBySection,
            findSectionBySlug

        }}>
            {children}
        </WidgetContext.Provider>
    )
}

/**
 *
 */
const useWidgetContext = () => {
    const widgetData = useContext(WidgetContext)
    if (widgetData === undefined) {
        throw new Error('useWidgetContext must be used inside WidgetProvider tags')
    }

    return { ...widgetData }
}

/**
 *
 */
export { WidgetContext, WidgetProvider, useWidgetContext, WidgetContextDataType }
